import React, { useState, useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import Navbar from './Navbar';
import Hamburger from './Hamburger';
import Sidebar from './Sidebar';
import { Wrapper, Overlay } from './styles';

export const Header = () => {
  const [sidebar, toggle] = useState(false);
  const { theme } = useContext(ThemeContext);
  return (
    <Wrapper id="headerMenu" theme={theme}>
      <div id="scrollBackground">
        <Overlay sidebar={sidebar} onClick={() => toggle(!sidebar)} />
        <Navbar />
        <Hamburger sidebar={sidebar} toggle={toggle} />
        <Sidebar sidebar={sidebar} toggle={toggle} />
      </div>
    </Wrapper>
  );
};
