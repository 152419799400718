import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper desktop={desktop} theme={theme}>
      <AnchorLink offset="50" href="#projects">
        Projects
      </AnchorLink>
      <AnchorLink offset="50" href="#dev">
        Dev
      </AnchorLink>
      <AnchorLink offset="50" href="#about">
        About
      </AnchorLink>
      <AnchorLink offset="50" href="#contact">
        Contact
      </AnchorLink>
      <ToggleTheme />
    </Wrapper>
  );
};

export default NavbarLinks;
