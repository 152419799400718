import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  background: transparent;
  position: fixed;

  div.solid {
    -webkit-box-shadow: 0px 1px 3px #707070;
    -moz-box-shadow: 0px 1px 3px #707070;
    box-shadow: 0px 1px 3px #707070;
    background-color: ${({ theme }) => (theme === 'light' ? '#fff' : '#212121')};
  }
`;

export const Overlay = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: none;
  transition: 0.4s;

  ${({ sidebar }) =>
    sidebar &&
    `
			display: block;
			z-index: 4;	
	`}
`;
