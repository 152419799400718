module.exports = {
  defaultTitle: 'Jeannie Nguyen | Web Developer',
  logo: 'https://jeannnguyen.com/favicon/favicon-512.png',
  author: 'Jeannie Nguyen',
  url: 'https://jeannnguyen.com',
  legalName: 'Jeannie Nguyen',
  defaultDescription: 'I bring stories to life through code.',
  socialLinks: {
    github: 'https://github.com/jeannienguyen',
    linkedin: 'https://www.linkedin.com/in/jeannie-t-nguyen',
  },
  googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    twitter: '@smakosh',
  },
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
};
