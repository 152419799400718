import React from 'react';
import { Wrapper, Links, Details } from './styles';
import social from './social.json';

export const Footer = () => (
  <Wrapper>
    <Links>
      {social.map(({ id, name, link, icon }) => (
        <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
          <img width="24" src={icon} alt={name} />
        </a>
      ))}
    </Links>
    <Details>
      <span>© {new Date().getFullYear()} Jeannie Nguyen</span>
    </Details>
  </Wrapper>
);
